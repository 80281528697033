import { createApp} from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';

import http from "./util/http";
import base64 from 'base-64'
//import html2canvas from 'html2canvas'

import '@/assets/style.css';
import HeadNav from './components/HeadNav.vue'
import collectWidget from "@/components/collectWidget";
import canvasRegno from "@/components/canvasRegno";

const app=createApp(App).use(router).use(Vant)
    .component('HeadNav',HeadNav)
    .component('canvasRegno',canvasRegno)
    .component('collectWidget',collectWidget);
app.mount('#app')


//let dev_url="https://my.api.jdsb.cn";//开发环境
//let dev_url="https://192.168.0.139";//开发环境
let dev_url="http://192.168.0.139:8043";//开发环境

let pro_url="https://api.jdsb.cn";//生产环境
if(process.env.NODE_ENV==="development")http.defaults.baseURL=dev_url;
else http.defaults.baseURL=pro_url;
app.config.globalProperties.$http = http;
app.config.globalProperties.$base64 = base64;


/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined ? '' : to.meta.title
    next();
})