<template>
    <HeadNav :titles="titles" :showHd="shd" :back_btn="false" back_text=""></HeadNav>
    <div class="home">
      <van-list class="home-view">
          <van-row justify="space-between">
              <van-col span="12" v-for="(n,idx) in icon_list" v-bind:index="idx" v-bind:key="n" class="home-col" @click="gotosearch(n.intcls)">
                  <van-icon :name="n.src" size="30" class="home-icon"></van-icon>
                  <span class="home-icon-text">{{n.text}}</span>
              </van-col>

          </van-row>
      </van-list>
    </div>
</template>
<style scoped>
    .home-view{
        padding:10px;
        height: calc(100vh - 120px);
        overflow:auto;
    }
    .home-view .van-row .van-col{
        background-color: #fff;
        line-height: 50px;
        text-align: center;
        background-clip: content-box;
        font-size: 13px;
        padding: 5px;
        /*box-shadow:1px 1px 4px 0px #d4d4d4;*/
    }
    .home-icon{
        display:inline-block;
        vertical-align: middle;
    }
    .home-icon-text{
        margin-left:5px;
        vertical-align: middle;
    }
</style>
<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios";
export default {
    data(){
        return {
            mmsg:'ok',
            titles:this.$route.meta.title,
            shd:true,
            icon_list:[]
        }
    },
    name: 'HomeView',
    components: {
    //HelloWorld,
    //HeadNav,
    },
    created(){
        //console.log(this.$route)
        let that=this;
        let share_code=this.$route.params.share_code;
        that.mmsg=this.$route.params;
        if(share_code){
            that.$router.replace('/')
            this.$http.post("/enter_route",{share_code:share_code}).then(function (datas) {
                let data=datas.data;
                if(datas.errcode==0){
                    if(data.route_name=='search'){
                        that.$router.push({name:data.route_name,query:data.route_data})
                    }else if(data.route_name=='collect'){
                        that.$router.push({name:data.route_name,params:{sid:data.sid}})
                    }else{
                        that.$router.push({name:data.route_name,params:data.route_data})
                    }
                }
            }).catch(error=>{
                console.log(error)
                if(error.errcode==403)that.$router.push({path:'/login'})
                else that.$router.push({path:'/404',redirect:true})
            })
        }else{
            this.$http.post('/home_data',{sid:true}).then(function(datas){
                let data=datas.data;
                for(let k in data){
                    that.icon_list.push({src:require('../assets/icon/intcls_'+String(data[k].type).padStart(2,'0')+'.png'),text:data[k].classname,intcls:data[k].type});
                }
            }).catch(error=>{
                console.log(error)
                if(error.errcode==403)that.$router.push({path:'/login'})
                else that.$router.push({path:'/404',redirect:true})
            })
        }
    },
    activated(){
        //this.$router.options.routes[3].meta.keepAlive=false
    },
    methods:{
        gotosearch(intcls){
            this.$router.push({name:'tmlist',params:{type:intcls}})
        },
    }
}
</script>
